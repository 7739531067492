import Item from "../Item";
import {Row} from "read-excel-file";
import {ParamException, ParamStore} from "./Param";
import {isEmpty} from "lodash";
import {ifEmpty, nameToUrl, splitNamePattern} from "../../util/util";
import {VoidCallback} from "../../common/types";

export type Language = 'en' | 'ua' | 'pl' | 'ru'
const LANGUAGE_LIST: Language[] = ['en', 'ua', 'pl', 'ru']
const NAME_COLUMN_NAMES: string[] = [
    'Наименование товара (en_US)',
    'Наименование товара (pl_PL)',
    'Наименование товара (uk_UA)',
    'Наименование'
]

const isEuLanguage = (lng: Language): boolean => lng !== 'en'

function getNameColumnForLanguage(lang: Language): string {
    switch (lang) {
        case 'en':
            return 'Наименование товара (en_US)';
        case 'ua':
            return 'Наименование товара (uk_UA)';
        case 'pl':
            return 'Наименование товара (pl_PL)';
        case 'ru':
            return 'Наименование';
    }
}

class NameTranslation {
    constructor(
        public readonly type: string,
        public readonly selector: string[], // https://docs.google.com/spreadsheets/d/167TZqRucWcV7vcJnlWROKvxgzezLRXpbCvo1PT_Yba4/edit#gid=900375465
        public readonly en: string,
        public readonly ru: string,
        public readonly pl: string = ru,
        public readonly ua: string = ru
    ) {
    }

    /**
     * Type-Common
     * Тип с одним переводом на все товары
     */
    public static fromCommonRow(data: Row): NameTranslation {
        const name: string = data[0].toString()
        const en: string = data[1].toString()
        const ru: string = data[2].toString()
        const ua: string = data[3].toString()
        const pl: string = data[4].toString()
        return new NameTranslation(name, [], en, ru, pl, ua)
    }

    /**
     * Тип где много переводов
     */
    public static fromRow(data: Row, name: string): NameTranslation {
        const selector: string[] = data[1].toString().split(', ')
        const en: string = data[2].toString()
        const ru: string = data[3].toString()
        const ua: string = data[4].toString()
        const pl: string = data[5].toString()
        return new NameTranslation(name, selector, en, ru, pl, ua)
    }

    public getLang(lang: Language): string {
        switch (lang) {
            case 'en':
                return this.en;
            case 'ua':
                return this.ua;
            case 'pl':
                return this.pl;
            case 'ru':
                return this.ru;
        }
    }

    /**
     * Проверяет подходит ли товар для этого перевода.
     * Используется Exact Selector.
     * Если он отсутствует - проверяет наличие в названии всех селекторов.
     */
    public match(item: Item): boolean {
        if (item.values.get('Exact Selector') === this.selector.join(', ')) {
            return true;
        }
        const itemName: string | undefined = item.values.get('name')
        if (itemName === undefined) {
            return false;
        }
        return this.selector.every((it: string): boolean => itemName.includes(it));
    }
}

class NamePattern {
    constructor(
        public readonly type: string,
        public readonly en: string,
        public readonly ru: string,
        public readonly ua: string = ru,
        public readonly pl: string = ru
    ) {
    }

    public static fromRow(data: Row): NamePattern {
        const name: string = data[0].toString()
        const en: string = this.addQuotes(data[1].toString())
        const ru: string = this.addQuotes(data[2].toString())
        const ua: string = this.addQuotes(ifEmpty(data[3]?.toString(), ru))
        const pl: string = this.addQuotes(ifEmpty(data[4]?.toString(), ru))

        return new NamePattern(name, en, ru, ua, pl)
    }

    private static addQuotes(pattern: string): string {
        if (!pattern.startsWith("'")) {
            pattern = `'${pattern}`
        }
        if (!pattern.endsWith("'")) {
            pattern = `'${pattern}`
        }
        return pattern
    }

    public getLang(lang: Language): string {
        switch (lang) {
            case 'en':
                return this.en;
            case 'ua':
                return this.ua;
            case 'pl':
                return this.pl;
            case 'ru':
                return this.ru;
        }
    }
}

class NameProcessException extends Error {
    constructor(public readonly errors: string[], public readonly item: Item) {
        super((errors[0] ?? '') + ` item: ${item.getSku()}`);
    }
}

abstract class NameFormatStore {

    /** Wheel -> (Brand, sku, size) */
    public static readonly namePatterns: Map<string, NamePattern> = new Map()
    /** Wheel -> Диск Wheel Falgi */
    public static readonly translationCommon: Map<string, NameTranslation> = new Map()
    /** Led -> (Laser ,Roof ,Bar)[] */
    public static readonly translations: Map<string, NameTranslation[]> = new Map()

    public static getTypeList(): string[] {
        return [...Array.from(this.translationCommon.keys()), ...Array.from(this.translations.keys())]
    }

    /**
     * https://docs.google.com/spreadsheets/d/167TZqRucWcV7vcJnlWROKvxgzezLRXpbCvo1PT_Yba4
     * @param patterns Паттерны названия (бренд sku name size)
     * @param commons Тип где на все товары один перевод
     * @param others Map<НазваниеТипа, Список> Нужно подбирать перевод по селектору
     */
    public static init(patterns: Row[], commons: Row[], others: Map<string, Row[]>): void {
        for (const pattern of patterns) {
            if (isEmpty(pattern[0])) {
                continue
            }
            this.namePatterns.set(String(pattern[0]), NamePattern.fromRow(pattern))
        }
        for (const common of commons) {
            const name: string = String(common[0])
            this.translationCommon.set(name, NameTranslation.fromCommonRow(common))
        }
        for (const [name, data] of others) {
            const other: NameTranslation[] = data.filter((row: Row): boolean => {
                return !isEmpty(row[0]) && !isEmpty(row[1])
            }).map((row: Row): NameTranslation => {
                return NameTranslation.fromRow(row, name)
            })
            this.translations.set(name, other)
        }
    }

    /**
     * @return callback to apply changes to an item
     * @throws NameProcessException
     */
    public static processItem(item: Item, type: string, brand: string, createUrl: boolean): VoidCallback {
        const namePattern: NamePattern = this.namePatterns.get(type)!!
        let nameTranslation: NameTranslation | undefined
        if (this.translationCommon.has(type)) {
            nameTranslation = this.translationCommon.get(type)!!
        } else {
            nameTranslation = this.translations.get(type)!!.find((it: NameTranslation): boolean => it.match(item))
        }
        if (nameTranslation === undefined) {
            throw new NameProcessException(['переклад назви не знайдено'], item);
        }

        const errors: string[] = [];

        // update ONLY if no errors occurred
        const callbacks: VoidCallback[] = [];
        for (const lng of LANGUAGE_LIST) {
            const isEU: boolean = isEuLanguage(lng)
            const pattern: string = namePattern.getLang(lng)
            const baseName: string = nameTranslation!.getLang(lng)

            const name: string = splitNamePattern(pattern).map((param: string): string | null => {
                if (param === 'Brand' && !isEmpty(brand)) {
                    return brand
                }
                // param - название характеристики
                if (param === 'name') {
                    return baseName;
                }
                let result: string | null = null

                try {
                    result = ParamStore.getSpecialParam(param).getValue(param, item)?.get(isEU) ?? null;
                } catch (e: any) {
                    if (e instanceof ParamException) {
                        errors.push(e.error)
                    } else {
                        console.log(e)
                    }
                }
                return result;
            }).filter((it: string | null): boolean => it !== null).join(' ');

            callbacks.push((): void => {
                item.values.set(getNameColumnForLanguage(lng), name)
                if (createUrl && lng === 'en' && isEmpty(item.values.get('Ссылка на витрину'))) {
                    item.values.set('Ссылка на витрину', nameToUrl(name))
                }
            })
        }

        if (errors.length > 0) {
            throw new NameProcessException(errors, item)
        }

        return (): void => {
            callbacks.forEach((it: () => void): void => it());
            item.notify()
        }
    }
}

export {
    NamePattern,
    NameFormatStore,
    NameProcessException,
    LANGUAGE_LIST,
    NAME_COLUMN_NAMES,
}
