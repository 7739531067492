import {FileDownload} from "@mui/icons-material";
import {Fab} from "@mui/material";
import React, {ReactElement, useState} from "react";
import Item from "../data/Item";
import {downloadExcel} from "../util/util";
import toast from "react-hot-toast";

interface FileDownloaderProps {
    items: Item[],
    filename: string
}

const FileDownloader = ({items, filename}: FileDownloaderProps): ReactElement => {

    filename = filename.replace('.xlsx', '') + '-name.xlsx'

    const [zIndex, setZIndex] = useState<string>('auto')

    return <Fab
        onMouseEnter={(): void => setZIndex('1051')} onMouseLeave={(): void => setZIndex('auto')}
        sx={{
            zIndex: zIndex,
            position: 'fixed',
            bottom: 0,
            right: 480,
            float: 'right'
        }} color='primary'
        onClick={(): void => {
            downloadExcel(items, filename)
                .then((): void => {
                    toast.success(`Назва файлу - ${filename}`, {duration: 3000})
                })
                .catch((e): void => {
                    toast.error('Помилка')
                    console.log(e)
                })
        }}>
        <FileDownload/>
    </Fab>
}
export default FileDownloader
