import React, {ReactElement, useRef, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import {drop, take, takeRight, uniq} from "lodash";
import {MoreHoriz} from "@mui/icons-material";
import toast from "react-hot-toast";
import {VoidCallback} from "../../common/types";

interface NamingErrorRowProps {
    error: string;
    skus: string[];
}

const NamingErrorRow = ({error, skus}: NamingErrorRowProps): ReactElement => {
    skus = uniq(skus)
    const showMoreAnchor = useRef<HTMLButtonElement | null>(null);
    const [moreOpen, setMoreOpen] = useState<boolean>(false)

    return <TableRow>
        <TableCell>
            {error}
        </TableCell>
        <TableCell>
            <Grid container={true} direction='column' alignContent='center'>
                {take(skus, 3).map((sku: string): ReactElement => {
                    return <Grid key={sku} item={true} paddingLeft='6px'>
                        <span>{sku}</span>
                    </Grid>
                })}
                <Grid item={true}>
                    <IconButton onClick={(): void => {
                        setMoreOpen(true);
                    }} ref={showMoreAnchor}>
                        <MoreHoriz/>
                    </IconButton>
                </Grid>
                <Menu sx={{zIndex: 1302}} anchorEl={showMoreAnchor.current} open={moreOpen}
                      onClose={(): void => setMoreOpen(false)}>
                    {/* Show last 25 skus */}
                    {takeRight(drop(skus, 3), 25).map((sku: string): ReactElement => {
                        return <MenuItem key={sku} onClick={(): void => {
                            navigator.clipboard.writeText(sku).then((): void => {
                                toast.success('Скопійовано')
                            })
                        }}>
                            {sku}
                        </MenuItem>
                    })}
                </Menu>
            </Grid>
        </TableCell>
    </TableRow>
}

interface NamingErrorsProps {
    errors: Map<string, string[]>;
    close: VoidCallback;
}

const ErrorListMenu = ({errors, close}: NamingErrorsProps): ReactElement => {

    return <Dialog style={{zIndex: 1301}} open={errors.size > 0} onClose={close} keepMounted={false}>
        <DialogTitle>
            Помилки у вашому файлі:
        </DialogTitle>
        <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Помилка
                        </TableCell>
                        <TableCell>
                            Артикули
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...errors.entries()].map(([error, skus]: [string, string[]]): ReactElement => {
                        return <NamingErrorRow key={error} error={error} skus={skus}/>
                    })}
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Закрити</Button>
        </DialogActions>
    </Dialog>
}

export default ErrorListMenu;
