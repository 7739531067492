import {Backdrop, CircularProgress} from "@mui/material";
import React, {JSX} from "react";

const Loader = (): JSX.Element => {
    return <Backdrop style={{zIndex: 9000}} open={true}>
        <CircularProgress/>
    </Backdrop>
}

export default Loader;
