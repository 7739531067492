import {JSX, useMemo} from "react";
import {chunk} from "lodash";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import HeaderSwitch from "./HeaderSwitch";
import PresetSelector from "../preset/PresetSelector";

interface HeaderSwitchContainerProps {
    headers: string[],
    enabled: Map<string, boolean>,
    switchHeaderVisibility: (header: string) => void,
    selectPreset: (preset: string) => void,
    dialogOpen: boolean,
    close: () => void
}

const HeaderSwitchContainer = (props: HeaderSwitchContainerProps): JSX.Element => {
    const {
        headers,
        enabled,
        switchHeaderVisibility,
        selectPreset,
        dialogOpen,
        close
    } = props
    const switches: string[][] = useMemo((): string[][] => chunk(headers, 3), [headers])

    return <Dialog maxWidth='xl' open={dialogOpen} onClose={close}>
        <DialogTitle>
            <Grid justifyContent='space-between' container={true}>
                <Grid item={true}>
                    Показати і перевірити колонки
                </Grid>
                <Grid item={true}>
                    <PresetSelector selectPreset={selectPreset}/>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Table>
                <TableBody>
                    {switches.map((list: string[], index: number): JSX.Element => {
                        return <TableRow key={index}>
                            {list.map((it: string): JSX.Element => {
                                return <TableCell key={it}>
                                    <HeaderSwitch header={it} enabled={enabled.get(it)!}
                                                  switchHeaderVisibility={switchHeaderVisibility}/>
                                </TableCell>
                            })}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>
                Закрити
            </Button>
        </DialogActions>
    </Dialog>
}
export default HeaderSwitchContainer;
