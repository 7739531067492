import Item from "../Item";
import {VoidCallback} from "../../common/types";
import {BoltPatternList} from "./ReplaceRuleStore";
import {listToString, stringToList} from "../../util/util";

abstract class ReplaceRule {
    /**
     * @throws ReplaceError
     * @return callback to apply changes
     */
    public abstract replace(item: Item): VoidCallback;
}

class BoltPatternReplaceRule extends ReplaceRule {

    public constructor(private readonly list: BoltPatternList) {
        super();
    }

    public replace(item: Item): VoidCallback {
        const value: string | null = item.values.get('Разболтовка') ?? null;
        if (value === null) {
            throw new ReplaceError('Разболтовка не вказана', item);
        }

        const pattern: string = listToString(
            stringToList(value).map((it: string): string => {
                if ([...this.list.values()].includes(it)) {
                    return it;
                }
                console.log(it)
                const found: string | null = this.list.get(it) ?? null;
                if (found === null) {
                    throw new ReplaceError('Таку розболтовку не знайдено', item);
                }
                return found;
            })
        )

        return (): void => {
            item.values.set('Разболтовка', pattern);
        }
    }
}

class SubModelReplaceRule extends ReplaceRule {
    private static readonly sub: Map<string, string> = new Map([
        ['Dodge|Ram', 'TRX'],
        ['Ford', 'Raptor R|Raptor'],
        ['Jeep', 'Rubicon X|Rubicon 392|Rubicon|Sport S|Sport|Sahara']
    ]);

    private static readonly subs: Map<string, Map<string, string>> = new Map([
        ['Sub model', this.sub]
    ]);

    public replace(item: Item): VoidCallback {
        let cars: string[] = stringToList(item.values.get('auto') ?? '');
        if (cars.length === 0) {
            return (): void => {
            };
        }
        const submodels: Map<string, Set<string>> = new Map();
        cars = cars.filter((car: string): boolean => car.replace('|', '').trim().length > 0);
        cars = cars.map((auto: string): string => {
            SubModelReplaceRule.subs.forEach((mapping: Map<string, string>, spec: string): void => {
                mapping.forEach((autoRaw: string, subRaw: string): void => {
                    const autos: string[] = autoRaw.split('|');
                    if (!autos.some((it: string): boolean => auto.includes(it))) {
                        return;
                    }
                    const subs: string[] = subRaw.split('|');
                    subs.forEach((sub: string): void => {
                        if (auto.includes(sub)) {
                            if (!submodels.has(sub)) {
                                submodels.set(sub, new Set<string>());
                            }
                            submodels.get(sub)!.add(sub);
                            auto = auto.replace(sub, '');
                        }
                    });
                });
            });
            return auto.trim();
        });

        return (): void => {
            item.values.set('auto', listToString(cars));
            submodels.forEach((values: Set<string>, sub: string): void => {
                item.values.set(sub, listToString(Array.from(values)));
            });
        };
    }
}

class AutoReplaceRule extends ReplaceRule {

    private transform(cars: string[]): string {
        if (cars.includes("all")) return listToString(cars);
        if (cars.length === 0) return 'all';

        const years = cars.map((car: string): number => parseInt(car.split("||")[0]));
        const add = new Set<string>();

        const list = cars.map((car: string): string => {
            let modifiedCar = car
                .replace(/JL/gi, "JL")
                .replace(/JK/gi, "JK")
                .replace(/JT/gi, "JT")
                .replace(/New Body Style/gi, "New Model")
                .replace("1500 New Body Style", "1500 New Model")
                .replace("2500 New Body Style", "2500 New Model")
                .replace("3500 New Body Style", "3500 New Model")
                .replace(/1500 Classic/i, (match, _, string): string => {
                    return parseInt(string.split("||")[0]) < 2019 ? "1500" : match;
                })
                .replace("1500 Limited", "1500")
                .replace(/Gladiator/i, (match, _, string): string => {
                    return !string.includes("Gladiator JT") ? "Gladiator JT" : match;
                })
                .replace("Silverado 1500 LD (Legacy)", "Silverado 1500")
                .replace("Super Duty", "")
                .replace("Chevy", "Chevrolet")
                .replace("HD", "")
                .replace("LD", "")
                .replace("New Body Style", "New Model")
                .replace("||JT", "||Gladiator");

            ["150", "250", "350", "450", "550", "650"].forEach((it: string): void => {
                modifiedCar = modifiedCar.replaceAll(`F${it}`, `F-${it}`);
            });

            if (modifiedCar.includes('Chevrolet') || modifiedCar.includes('GMC')) {
                modifiedCar = modifiedCar.replace('New Model', '')
            }

            modifiedCar = modifiedCar.trim()
            if (modifiedCar.toLowerCase() === 'all' || modifiedCar.toUpperCase() === 'ALL') {
                return modifiedCar;
            }

            const splitted = modifiedCar.split("||");
            if (splitted.length < 3) {
                return modifiedCar;
            }

            if (splitted[1] === "Jeep" && splitted[2] === "Wrangler") {
                const year = parseInt(splitted[0]);
                if (year > 2018) splitted[2] += " JL";
                else if (year >= 2007) splitted[2] += " JK";
                else if (year >= 1997) splitted[2] += " TJ";
                else if (year >= 1987) splitted[2] += " YJ";
                else if (year >= 1945) splitted[2] += " CJ";
            }

            if (parseInt(splitted[0]) >= 2019 && (splitted[1] === "Ram" || splitted[1] === "Dodge") && splitted[2].endsWith("1500")) {
                if (years.some(y => y >= 2005 && y <= 2018)) {
                    splitted[2] += " Classic";
                } else {
                    splitted[2] += " New Model";
                }
            }

            if (parseInt(splitted[0]) === 2019 && years.some((y: number): boolean => y >= 2020 && y <= 2023) &&
                (splitted[1] === "GMC" || splitted[1] === "Chevrolet") &&
                (splitted[2].includes("Sierra") || splitted[2].includes("Silverado")) &&
                splitted[2].endsWith("1500")) {
                splitted[2] += " New Model";
            }

            if (parseInt(splitted[0]) === 2022 && years.includes(2023) &&
                (splitted[1] === "GMC" || splitted[1] === "Chevrolet") &&
                (splitted[2].includes("Sierra") || splitted[2].includes("Silverado")) &&
                splitted[2].endsWith("1500")) {
                add.add(`2020||${splitted[1]}||${splitted[2]}`);
                splitted[2] += " New Model";
            }

            if (parseInt(splitted[0]) === 2022 && years.some((y: number): boolean => y >= 2023 && y <= 2024) &&
                (splitted[1] === "GMC" || splitted[1] === "Chevrolet") &&
                (splitted[2].includes("Sierra") || splitted[2].includes("Silverado")) &&
                splitted[2].endsWith("1500")) {
                splitted[2] += " New Model";
            }

            splitted[2] = splitted[2].replace("LD", "").trim();
            splitted[2] = splitted[2].replace("LTD", "").trim();
            return splitted.join("||");
        }).map((car: string): string => {
            const splitted = car.split("||");
            if (splitted[1] === "Dodge" && splitted[2].includes("Ram")) {
                splitted[1] = "Ram";
                splitted[2] = splitted[2].replace("Ram", "").trim();
            } else if (splitted[1] === "Ram") {
                splitted[1] = "Dodge";
                splitted[2] = "Ram " + splitted[2];
            }
            return splitted.join("||");
        }).concat([...add])
            .filter((value: string, index: number, self: string[]): boolean => self.indexOf(value) === index);

        const addRam: string[] = list.filter((it: string): boolean => {
            return it.includes('Ram');
        }).map((it: string): string => {
            const splitted = it.split('||')
            if (splitted[1] === 'Dodge') {
                splitted[1] = 'Ram';
            } else {
                splitted[1] = 'Dodge';
                splitted[2] = 'Ram ' + splitted[2]
            }
            return splitted.join('||');
        });
        list.push(...addRam);

        return listToString(list);
    }

    public replace(item: Item): VoidCallback {
        let cars = stringToList(item.values.get('auto') ?? '');
        if (cars.length === 0) {
            return () => {
            };
        }
        cars = cars.filter(car => car.replace('|', '').trim().length > 0);

        return (): void => {
            item.values.set('auto', this.transform(cars));
        };
    }
}

class ReplaceError extends Error {
    public constructor(message: string, public readonly item: Item) {
        super(message);
    }
}

export {
    ReplaceRule,
    ReplaceError,
    BoltPatternReplaceRule,
    AutoReplaceRule,
    SubModelReplaceRule,
}
