import {ReactElement} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    List,
    ListItem,
    ListItemText
} from "@mui/material";

interface DocumentsModalProps {
    isOpen: boolean;
    close: () => void;
}

const LINKS: { [k: string]: string } = {
    'Переклади': 'https://docs.google.com/spreadsheets/d/1QblNDub83e1DkdNqlXIa_Wx6RY8nz9irr2LbJ3QJM0E',
    'Формат назв': 'https://docs.google.com/spreadsheets/d/167TZqRucWcV7vcJnlWROKvxgzezLRXpbCvo1PT_Yba4',
    'Типи даних': 'https://docs.google.com/spreadsheets/d/1oq7ntaWpW6C0NgNI-2VaUQ27KKKG6qBSReCXKP2O3BI',
    'Формат розболтовки': 'https://docs.google.com/spreadsheets/d/1ejtKQ_skZFv6oAi69wKrk83bRKYV6Op8DxGrPDoX5CY',
    'Адмінка 4х4': 'https://4x4ok.com.ua/webasyst',
    '4x4ok.com.ua': 'https://4x4ok.com.ua/'
}

const DocumentsModal = ({isOpen, close}: DocumentsModalProps): ReactElement => {

    return <Dialog open={isOpen} onClose={close}>
        <DialogTitle>
            Корисні посилання
        </DialogTitle>
        <DialogContent>
            <List>
                {Object.entries(LINKS).map(([name, link]: [string, string]): ReactElement => {
                    return <ListItem key={name} component={Link} target='_blank' href={link}>
                        <ListItemText>{name}</ListItemText>
                    </ListItem>
                })}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Закрити</Button>
        </DialogActions>
    </Dialog>
}

export default DocumentsModal;
