import {flatten, isEmpty, maxBy, toNumber, uniq} from "lodash";
import writeXlsxFile from "write-excel-file";
import {saveAs} from "file-saver";
import Item from "../data/Item";

const GOOGLE_DOCS_FORMAT_LINK = 'https://docs.google.com/spreadsheets/d/1oq7ntaWpW6C0NgNI-2VaUQ27KKKG6qBSReCXKP2O3BI/export?format=xlsx&id=1oq7ntaWpW6C0NgNI-2VaUQ27KKKG6qBSReCXKP2O3BI'
const GOOGLE_DOCS_NAME_FORMAT_LINK = 'https://docs.google.com/spreadsheets/d/167TZqRucWcV7vcJnlWROKvxgzezLRXpbCvo1PT_Yba4/export?format=xlsx&id=167TZqRucWcV7vcJnlWROKvxgzezLRXpbCvo1PT_Yba4'
const GOOGLE_DOCS_BOLT_PATTERN_LINK = 'https://docs.google.com/spreadsheets/d/1ejtKQ_skZFv6oAi69wKrk83bRKYV6Op8DxGrPDoX5CY/export?format=xlsx&id=1ejtKQ_skZFv6oAi69wKrk83bRKYV6Op8DxGrPDoX5CY'

function isNumeric(value: any): boolean {
    if (typeof value !== 'string') {
        return false;
    }
    value = value.replace(',', '.')
    return !isNaN(value) && !isNaN(parseFloat(value))
}

function listToString(values: string[]): string {
    if (values.length === 0) {
        return '';
    }
    if (values.length === 1) {
        return values[0];
    } else {
        return `{${Array.from(new Set(values)).join(',')}}`;
    }
}

function stringToList(value: string): Array<string> {
    let result: Set<string>
    if (isStringList(value)) {
        result = new Set(value.substring(1, value.length - 1).split(','));
    } else {
        result = new Set([value]);
    }
    return Array.from(result);
}

function filterNotEmpty<T>(array: T[]): T[] {
    return array.filter((it: T): boolean => !isEmpty(it))
}

function isStringList(value: string): boolean {
    return value[0] === '{' && value.slice(-1) === '}';
}

function countDecimals(value: number): number {
    if (Math.floor(value) === value.valueOf()) {
        return 0;
    }
    return value.toString().split(".")[1].length || 0;
}

function isWholeNumber(value: number): boolean {
    if (isNaN(value)) {
        return false;
    }
    return toNumber(value) % 1 === 0;
}

function nameToUrl(_str: string): string {
    let str: string = _str;
    const ILLEGAL_CHARACTERS: string[] = [
        '/',
        '\n',
        '\r',
        '\t',
        '\u0000',
        '\u000c',
        '`',
        '?',
        '*',
        '\\',
        '<',
        '>',
        '|',
        '"',
        ':',
        '&',
        ' ',
        '.',
        ',',
        '+',
        '®'
    ];
    ILLEGAL_CHARACTERS.forEach((char: string): void => {
        str = str.replace(new RegExp('\\' + char, 'g'), '-');
    });
    while (str.includes('--')) {
        str = str.replace('--', '-');
    }
    if (str.startsWith('-')) {
        str = str.substring(1)
    }
    if (str.endsWith('-')) {
        str = str.slice(0, -1)
    }

    return str.toLowerCase();
}


class Pair<F, S> {
    constructor(public first: F, public second: S) {
    }
}

function ifEmpty<T>(val: T, other: T): T {
    return isEmpty(val) ? other : val
}

class NumberRange {
    constructor(public readonly start: number, public readonly end: number) {
    }

    public static fromString(val: string): NumberRange {
        const splitted: string[] = val.split('-')
        return new NumberRange(Number(splitted[0]), Number(splitted[1]))
    }

    public contains(num: number): boolean {
        return num >= this.start && num <= this.end;
    }
}

function splitNamePattern(pattern: string): string[] {
    return pattern.match(/'([^']*)'/g)!.map((item: string): string => item.replace(/'/g, ""));
}

function substringBefore(string: string, separator: string): string {
    const index: number = string.indexOf(separator);
    return index === -1 ? string : string.substring(0, index);
}

function countChar(string: string, char: string): number {
    let count: number = 0;
    for (let i: number = 0; i < string.length; i++) {
        if (string[i] === char) {
            count++;
        }
    }
    return count;
}

async function downloadExcel(items: Item[], filename: string): Promise<void> {
    const headers: string[] = uniq(flatten(items.map((it: Item): string[] => Array.from(it.values.keys()))))

    // move images to the end
    const index: number = headers.indexOf('Изображения товаров');
    if (index !== -1) {
        const item: string = headers.splice(index, 1)[0];
        headers.push(item);
    }

    const rows = items.map((item: Item): (string | undefined)[] => {
        return flatten(headers.map((it: string) => {
            const val = item.values.get(it)
            if (isEmpty(val)) {
                return [undefined]
            }
            if (it === 'Изображения товаров') {
                return val!.split(';').filter((it: string): boolean => !isEmpty(it))
            }
            return [val]
        }))
    }).map((row: (string | undefined)[]) => {
        return row.map((it: string | undefined) => {
            if (isEmpty(it)) {
                return it;
            }
            return {value: it}
        })
    })

    const maxImages: number = countChar(
        maxBy(items, (it: Item): number => {
            return it.values.get('Изображения товаров')?.split('').length ?? 0
        })!.values.get('Изображения товаров') ?? '',
        ';')
    for (let i = 0; i <= maxImages; ++i) {
        headers.push('Изображения товаров')
    }

    const headerCells = headers.map((it: string) => ({value: it, fontWeight: 'bold'}))
    const data = [headerCells, ...rows]
    // @ts-ignore
    const file: Blob = await writeXlsxFile(data, {sheet: 'Default Sheet'})
    saveAs(file, filename)
}

function substringAfter(str: string, separator: string): string {
    const index: number = str.indexOf(separator);
    return index === -1 ? str : str.substring(index + separator.length);
}

function removeExtraSpaces(str: string): string {
    return str.replace(/\s+/g, ' ');
}

export {
    isNumeric,
    listToString,
    stringToList,
    countDecimals,
    isStringList,
    isWholeNumber,
    GOOGLE_DOCS_FORMAT_LINK,
    GOOGLE_DOCS_NAME_FORMAT_LINK,
    GOOGLE_DOCS_BOLT_PATTERN_LINK,
    NumberRange,
    filterNotEmpty,
    Pair,
    nameToUrl,
    ifEmpty,
    downloadExcel,
    countChar,
    splitNamePattern,
    substringAfter,
    substringBefore,
    removeExtraSpaces,
};
