import {ChangeEvent, JSX, MutableRefObject, useRef, useState} from "react";
import {
    Button,
    CircularProgress,
    Fab,
    Grid,
    IconButton,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import RuleStore from "../data/verify/RuleStore";
import {Add, ArrowBack, Delete, Save} from "@mui/icons-material";
import Loader from "../common/Loader";
import {isEmpty} from "lodash";
import toast, {Toaster} from "react-hot-toast";
import PasswordPrompt from "./PasswordPrompt";

const FloatingButton = styled(Fab)({
    position: 'fixed',
    float: 'right',
    zIndex: 100,
    top: 50,
    right: 50
});

const ListsEditor = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false)

    const [lists, setLists] = useState<Map<string, string[]>>(new Map(RuleStore.lists))

    const [passwordOpen, setPasswordOpen] = useState<boolean>(false)

    const nameRef: MutableRefObject<HTMLInputElement | undefined> = useRef()

    const save = (password: string): void => {
        setLoading(true)
        const data: { [key: string]: string[] | string } = Object.fromEntries(lists.entries())
        data.password = password
        fetch('/update.php', {
            method: 'POST',
            body: JSON.stringify(data)
        }).then((res: Response): void => {
            if (!res.ok) {
                throw new Error('', {cause: res})
            }
            toast.success('Збережено', {duration: 3000})
        }).catch((e): void => {
            toast.error('Помилка!', {duration: 3000})
            console.log('Save error', e)
        }).finally((): void => {
            setLoading(false)
        })
    }

    const addParam = (): void => {
        const name: string = nameRef.current!!.value
        if (isEmpty(name)) {
            toast.error('Введіть назву')
            return
        }
        if (lists.has(name)) {
            toast.error('Характеристику вже додано')
            return
        }
        const newList: Map<string, string[]> = new Map(lists)
        newList.set(name, [])
        setLists(newList)
        toast.success(`Характеристику ${name} додано`)
        nameRef.current!!.value = ''
    }

    const deleteParam = (name: string): void => {
        const newList: Map<string, string[]> = new Map(lists)
        newList.delete(name)
        setLists(newList)
        toast.success(`Характеристику ${name} видалено`)
    }

    const back = (): void => {
        window.location.href = '/'
    }

    return <>
        {loading && <Loader/>}
        <PasswordPrompt
            isOpen={passwordOpen}
            close={(): void => setPasswordOpen(false)}
            runUpdate={save}
        />
        <FloatingButton color='primary' onClick={(): void => setPasswordOpen(true)}>
            {loading ? <CircularProgress color='inherit'/> : <Save/>}
        </FloatingButton>
        <FloatingButton color='primary' sx={{float: 'left', left: 50}} onClick={back}>
            <ArrowBack/>
        </FloatingButton>
        <Toaster/>
        <Grid marginTop='45px' marginBottom='50px' container={true}>
            <Grid item={true} lg={4}/>
            <Grid item={true} lg={8}>
                <TableContainer component={Paper} sx={{width: '60%'}}>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Характеристика
                                </TableCell>
                                <TableCell>
                                    Значення, кожне з нової строки
                                </TableCell>
                                <TableCell>
                                    Видалити
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from(lists.entries()).map(([key, values]: [string, string[]]): JSX.Element => {
                                return <TableRow hover={true} key={key}>
                                    <TableCell>
                                        {key}
                                    </TableCell>
                                    <TableCell>
                                        <textarea rows={values.length} defaultValue={values.join('\n')}
                                                  onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
                                                      lists.set(key, e.target.value.split("\n"))
                                                  }}/>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton color='primary' onClick={(): void => deleteParam(key)}>
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            })}
                            <TableRow>
                                <TableCell colSpan={1000}>
                                    <Button startIcon={<Add/>} onClick={addParam}>
                                        Додати
                                    </Button>
                                    <TextField
                                        variant='standard'
                                        inputProps={{ref: nameRef}}
                                        defaultValue=''
                                        placeholder='Названа характеристики'
                                        sx={{width: '210px', marginLeft: '20px'}}/>
                                </TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        <ScrollToTop/>
    </>
}

export default ListsEditor;
