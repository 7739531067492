import {AutoReplaceRule, BoltPatternReplaceRule, ReplaceRule, SubModelReplaceRule} from "./ReplaceRule";
import {BoltPattern} from "../verify/Rule";

/**
 * (inch & mm) -> format
 * 4x108 -> 4x108mm (4x4.25")
 * 4x4.25 -> 4x108mm (4x4.25")
 */
export type BoltPatternList = Map<string, string>;

export type ReplaceRuleType = 'Разболтовка' | 'auto' | 'Sub models'

abstract class ReplaceRuleStore {

    public static readonly rules: Map<string, ReplaceRule> = new Map<ReplaceRuleType, ReplaceRule>();

    public static init(boltPattern: BoltPatternList): void {
        BoltPattern.list = [...boltPattern.values()];
        this.rules.set('Разболтовка', new BoltPatternReplaceRule(boltPattern));
        this.rules.set('auto', new AutoReplaceRule());
        this.rules.set('Sub models', new SubModelReplaceRule());
    }

    public static getRule(name: ReplaceRuleType): ReplaceRule {
        return this.rules.get(name)!;
    }
}

export {
    ReplaceRuleStore,
};
