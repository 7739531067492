import {ReactElement, useState} from "react";
import {VoidCallback} from "../../common/types";
import Item from "../../data/Item";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Loader from "../../common/Loader";
import {ReplaceRuleStore, ReplaceRuleType} from "../../data/replace/ReplaceRuleStore";
import ErrorListMenu from "../common/ErrorListMenu";
import toast from "react-hot-toast";
import {ReplaceError, ReplaceRule} from "../../data/replace/ReplaceRule";

interface ReplacementModalProps {
    isOpen: boolean;
    close: VoidCallback;
    items: Item[];
}

const ReplacementModal = ({isOpen, close, items}: ReplacementModalProps): ReactElement => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [ruleType, setRuleType] = useState<ReplaceRuleType | '0'>('0')

    const [errors, setErrors] = useState<Map<string, string[]>>(new Map())

    const doReplacement = (): void => {
        if (ruleType === '0') {
            toast.error('Оберіть характеристику');
            return;
        }
        setLoading(true)
        const rule: ReplaceRule = ReplaceRuleStore.rules.get(ruleType)!;
        const errors: Map<string, string[]> = new Map<string, string[]>()
        const callbacks: VoidCallback[] = []
        for (const item of items) {
            try {
                callbacks.push(rule.replace(item))
            } catch (e) {
                if (e instanceof ReplaceError) {
                    if (errors.has(e.message)) {
                        errors.get(e.message)!.push(e.item.getSku()!)
                    } else {
                        errors.set(e.message, [e.item.getSku()!])
                    }
                } else {
                    console.log(e)
                }
            }
        }
        if (errors.size === 0) {
            callbacks.forEach((it: VoidCallback): void => it())
            items.forEach((it: Item): void => it.notify())

            toast.success('Успіх. Можете завантажити файл')
            setLoading(false)
            return;
        }
        setErrors(errors)
        toast.error('Виникли помилки')
        setLoading(false)
    }

    return <>
        {isLoading && <Loader/>}
        <ErrorListMenu errors={errors} close={(): void => setErrors(new Map())}/>
        <Dialog fullWidth={true} open={isOpen} onClose={close}>
            <DialogTitle>
                Заміна характеристик
            </DialogTitle>
            <DialogContent>
                <FormControl sx={{marginTop: '20px'}} fullWidth>
                    <InputLabel id="replace_type_label">Характеристика</InputLabel>
                    <Select
                        labelId="replace_type_label"
                        id="replace_type_select"
                        value={ruleType}
                        label="Характеристика"
                        onChange={(e: SelectChangeEvent<ReplaceRuleType | '0'>): void => {
                            setRuleType(e.target.value as ReplaceRuleType)
                        }} variant='standard'
                    >
                        <MenuItem disabled={true} value='0'>Оберіть</MenuItem>
                        {[...ReplaceRuleStore.rules.keys()].map((it: string): ReactElement => {
                            return <MenuItem key={it} value={it}>{it}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Закрити</Button>
                <Button onClick={doReplacement}>Замінити</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default ReplacementModal;
