import Item, {VerifyRuleErrorList} from "../data/Item";
import {JSX, useEffect, useState} from "react";
import {Grid, TableCell, TableRow, Theme, Tooltip, Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {isEmpty, take, uniq} from "lodash";

interface RowProps {
    item: Item,
    shownHeaders: string[]
}

const Row = ({item, shownHeaders}: RowProps): JSX.Element => {
    const [, forceUpdate] = useState<number>(-1)

    useEffect((): () => void => {
        return item.subscribeForUpdates((): void => forceUpdate(Math.random()));
    }, [item, forceUpdate])

    return <TableRow hover={true}>
        <TableCell>{item.id}</TableCell>
        {shownHeaders.map((it: string): JSX.Element => {
            const errors: VerifyRuleErrorList = item.getErrors(shownHeaders)
            if (!isEmpty(errors[it])) {
                const sx: SxProps<Theme> = {
                    backgroundColor: 'rgb(192,56,56)',
                    borderRadius: 10,
                }
                return <Tooltip key={it} placement='top'
                                title={
                                    <>
                                        <Typography variant='h5'>
                                            {errors[it].rule.comment}
                                        </Typography>
                                        <Grid container={true} direction='column'>
                                            {take(uniq(errors[it].errors), 3).map((it: string): JSX.Element => {
                                                return <Grid key={it} item={true}>
                                                    <Typography variant='h6'>{it}</Typography>
                                                </Grid>
                                            })}
                                        </Grid>
                                    </>
                                }>
                    <TableCell sx={sx}>
                        {item.values.get(it)}
                    </TableCell>
                </Tooltip>
            }
            return <TableCell key={it}>
                {item.values.get(it)}
            </TableCell>
        })}
    </TableRow>
}

export default Row;
