import React, {JSX, useEffect, useState} from 'react';
import './App.css';
import FileUploader from "./file/FileUploader";
import Table from "./table/Table";
import Item from "./data/Item";
import {Toaster} from "react-hot-toast";
import Loader from "./common/Loader";
import ErrorModal from "./common/Error";
import ListsEditor from "./lists_editor/ListsEditor";
import {loadFormat, loadNameFormat, loadReplaceRule} from "./util/loader";
import FileDownloader from "./file/FileDownloader";
import {AutoReplaceRule} from "./data/replace/ReplaceRule";
import {stringToList} from "./util/util";

function App(): JSX.Element {
    const [items, setItems] = useState<Item[]>([])
    const [filename, setFilename] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [dataLoadingError, setDataLoadingError] = useState<boolean>(false)

    useEffect((): void => {
        if (!isLoading) {
            return
        }
        const promises: Promise<void>[] = [];
        promises.push(loadFormat())
        promises.push(loadNameFormat())
        promises.push(loadReplaceRule())
        Promise
            .all(promises)
            .catch((e): void => {
                console.log(e)
                setDataLoadingError(true)
            })
            .finally((): void => setIsLoading(false))
    }, [isLoading])

    // @ts-ignore
    window.replaceCar = (data: string) => {
        // @ts-ignore
        return (new AutoReplaceRule()).transform(stringToList(data))
    }

    if (isLoading) {
        return <Loader/>
    }

    if (dataLoadingError) {
        return <ErrorModal/>
    }

    if (window.location.hash === '#lists') {
        return <ListsEditor/>
    }

    return <>
        <Toaster/>
        <FileUploader itemsUploaded={items.length !== 0} setItems={setItems} setFilename={setFilename}/>
        <FileDownloader items={items} filename={filename}/>
        <Table items={items}/>
    </>
}

export default App;
