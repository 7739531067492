import {isEmpty} from "lodash";
import {countChar, countDecimals, isNumeric, isStringList, isWholeNumber, stringToList} from "../../util/util";
import RuleStore, {Auto, RuleType, SubModelType} from "./RuleStore";
import Item from "../Item";

/**
 * Should be treated as abstract
 */
class VerifyRule {

    // noinspection JSUnusedGlobalSymbols
    public constructor(public readonly type: RuleType, public readonly comment: string = '', public readonly example: string = '', ..._additional: any[]) {
    }

    // true if test passed, false or error array on failure
    public verify(_name: string, _value: string, ..._additional: any[]): string[] | boolean {
        const msg: string = `Instantiated VerifyRule which should be treated as abstract, or 'verify' isn't implemented\ntype=${this.type}, name='${_name}', value='${_value}'`
        console.log(msg)
        alert(msg)
        return [];
    }
}

class RequiredRule extends VerifyRule {
    public verify(_: string, value: string): boolean {
        return !isEmpty(value);
    }
}

class WholeNumberRule extends VerifyRule {
    public verify(_: string, value: string): boolean {
        return isWholeNumber(Number(value));
    }
}

class NumberRule extends VerifyRule {
    public verify(_: string, value: string): boolean {
        return isNumeric(value);
    }
}

class NumberWithDigitsRule extends VerifyRule {
    public constructor(type: RuleType, comment: string, example: string, public readonly maxDigits: number) {
        super(type, comment, example);
    }

    public verify(_: string, value: string): boolean {
        if (!isNumeric(value)) {
            return false;
        }
        return countDecimals(parseFloat(value)) <= this.maxDigits;
    }
}

/**
 * Range or number with N digits
 * 9", 9, 9-10"
 */
class NumberRangeRule extends NumberWithDigitsRule {
    public verify(_: string, value: string): boolean {
        if (countChar(value, '-') > 1) {
            return false;
        }
        return value.split('-').every((value: string, index: number): boolean => {
            if (index === 1) {
                value = value.replace('"', '')
            }
            if (!isNumeric(value)) {
                return false;
            }
            return countDecimals(parseFloat(value)) <= this.maxDigits;
        });
    }
}

class FractionalNumberRule extends VerifyRule {
    public verify(_name: string, value: string): boolean {
        return !isWholeNumber(Number(value))
    }
}

class InListRule extends VerifyRule {
    public verify(name: string, value: string): boolean {
        const list: string[] | undefined = RuleStore.lists.get(name)
        if (list === undefined) {
            return false;
        }
        return list.includes(value)
    }
}

class IsSingleRule extends VerifyRule {
    public verify(_: string, value: string): boolean {
        return !isStringList(value);
    }
}

class BoltPattern extends VerifyRule {

    public static list: string[];

    public verify(_: string, value: string): boolean {
        return BoltPattern.list.includes(value);
    }
}

class LedSize extends VerifyRule {
    public verify(_: string, value: string): boolean {
        if (!value.includes('"')) {
            return false;
        }
        if (!value.includes('x')) {
            return value.endsWith('"') && isNumeric(value.replace('"', ''))
        }
        const splitted: string[] = value.split('x')
        if (splitted.length !== 2) {
            return false;
        }
        return splitted.every((it: string): boolean => {
            return isNumeric(it.replace('"', ''))
        })
    }
}

class StringRule extends RequiredRule {
}

class SubmodelRule extends VerifyRule {
    public verify(name: string, value: string, auto: string): boolean | string[] {
        if (isEmpty(value)) {
            return true;
        }
        const errors: string[] = [];
        const list: string[] = stringToList(value)
        const itemAutos: [string, number][] = stringToList(auto).map((it: string): [string, number] => {
            const splitted: string[] = it.split('||')
            const year: number = Number(splitted.shift())
            return [splitted.join('||'), year];
        });
        const models: Map<string, Auto[]> = RuleStore.sub_model_to_auto.get(name as keyof SubModelType)!

        list.forEach((sub: string): void => {
            const autos: Auto[] | null = models.get(sub) ?? null
            if (autos === null) {
                errors.push(`Суб модель ${sub} не найдено`)
                return
            }
            const intersects: boolean = autos.some((it: Auto): boolean => {
                return itemAutos.some((itemAuto: [string, number]): boolean => {
                    return it.years.contains(itemAuto[1]) && it.name === itemAuto[0]
                })
            })
            if (!intersects) {
                errors.push(`Авто с суб моделью '${sub}' не найдено`)
            }
        })
        if (errors.length === 0) {
            return true;
        }
        return errors;
    }
}

class AutoRule extends VerifyRule {
    /**
     * Название авто -> Объекты с данным об авто
     * Ford||Ranger -> [97-98 Ranger, 01-22 Ranger]
     */
    public static autoList: Map<string, Auto[]>

    public verify(_: string, value: string): string[] | boolean {
        const autos: string[] = stringToList(value);
        const errors: string[] = [];
        if (value.includes(',') && !isStringList(value)) {
            errors.push('Wrong list format')
            return errors;
        }
        autos.forEach((it: string): void => {
            // 1998||Ford||Ranger
            const splitted: string[] = it.split("||")
            const year: number = Number(splitted.shift())
            if (!isWholeNumber(year)) {
                errors.push(`${it} wrong year`)
                return;
            }
            const autosByName: Auto[] | null = AutoRule.autoList.get(splitted.join('||')) ?? []
            if (isEmpty(autosByName)) {
                errors.push(`Авто '${splitted.join('||')}' не найдено`)
                return;
            }

            const found: boolean = autosByName.some((it: Auto): boolean => it.years.contains(year)) ?? false
            if (!found) {
                errors.push(`${it} для этого года не найден`)
            }
        });

        return errors.length === 0 ? true : errors;
    }
}

class VerifyError {
    public constructor(public readonly rule: VerifyRule, public readonly errors: string[] = []) {
    }
}

class CompoundRule {
    public constructor(
        public readonly required: VerifyRule | undefined,
        public readonly isSingle: VerifyRule | undefined,
        public readonly mainRule: VerifyRule
    ) {
    }

    /**
     * @param name For example: ET, Размер фары
     * @param value Value as string
     * @param item
     */
    public getError(name: string, value: string, item: Item): VerifyError | null {
        if (this.required && !this.required.verify(name, value)) {
            return new VerifyError(this.required);
        }
        if (this.isSingle && !this.isSingle.verify(name, value)) {
            return new VerifyError(this.isSingle);
        }
        const additional: (string | undefined)[] = [];
        if (this.mainRule instanceof SubmodelRule) {
            additional.push(item.values.get('auto'))
        }
        const errors: string[] = [];
        let passed: boolean = true;
        if (isStringList(value)) {
            stringToList(value).forEach((it: string): void => {
                const result: boolean | string[] = this.mainRule.verify(name, it, ...additional)
                if (result === true) {
                    return;
                }
                if (result === false) {
                    passed = false;
                    return;
                }
                errors.push(...result as string[])
            })
        } else {
            const error: boolean | string[] = this.mainRule.verify(name, value, ...additional)
            if (error !== true) {
                if (error === false) {
                    passed = false
                } else {
                    errors.push(...error as string[])
                }
            }
        }

        if (!passed || errors.length !== 0) {
            return new VerifyError(this.mainRule, errors);
        }
        return null;
    }
}

export {
    RequiredRule,
    VerifyRule,
    WholeNumberRule,
    NumberRangeRule,
    NumberRule,
    NumberWithDigitsRule,
    InListRule,
    IsSingleRule,
    BoltPattern,
    FractionalNumberRule,
    StringRule,
    CompoundRule,
    AutoRule,
    SubmodelRule,
    VerifyError,
    LedSize
};
