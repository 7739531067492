import RuleStore, {InitData} from "../data/verify/RuleStore";
import readXlsxFile, {readSheetNames, Row} from "read-excel-file";
import {GOOGLE_DOCS_BOLT_PATTERN_LINK, GOOGLE_DOCS_FORMAT_LINK, GOOGLE_DOCS_NAME_FORMAT_LINK} from "./util";
import {NameFormatStore} from "../data/name/NameFormat";
import {BoltPatternList, ReplaceRuleStore} from "../data/replace/ReplaceRuleStore";

export const loadFormat = async (): Promise<void> => {
    const typesNPresets: InitData = await fetch(!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'data-example.json' : '/data.json')
        .then((it: Response): Promise<InitData> => {
            if (!it.ok) {
                throw new Error('', {cause: it})
            }
            return it.json()
        })
    const autoList: Row[] = await fetch(GOOGLE_DOCS_FORMAT_LINK)
        .then((it: Response): Promise<Blob> => it.blob())
        .then((it: Blob): Promise<Row[]> => readXlsxFile(it, {sheet: 'auto'}))

    RuleStore.init(typesNPresets, autoList)
}

export const loadNameFormat = async (): Promise<void> => {
    const formatFile: Blob = await fetch(GOOGLE_DOCS_NAME_FORMAT_LINK).then((it: Response) => it.blob())
    // https://docs.google.com/spreadsheets/d/167TZqRucWcV7vcJnlWROKvxgzezLRXpbCvo1PT_Yba4/edit#gid=1213498636
    // Паттерн названия
    const patterns: Row[] = await readXlsxFile(formatFile, {sheet: 'Name Format'})
    patterns.shift()
    const commonTranslations: Row[] = await readXlsxFile(formatFile, {sheet: 'Type-Common'})
    commonTranslations.shift()

    const otherTranslationsSheetNames: string[] = (await readSheetNames(formatFile)).filter((it: string): boolean => {
        return it.startsWith('Type-') && it !== 'Type-Common'
    })

    const otherTranslations: Map<string, Row[]> = new Map()

    for (const sheet of otherTranslationsSheetNames) {
        const rows: Row[] = await readXlsxFile(formatFile, {sheet})
        rows.shift()
        otherTranslations.set(sheet.replace('Type-', ''), rows)
    }
    NameFormatStore.init(patterns, commonTranslations, otherTranslations)
}

export const loadReplaceRule = async (): Promise<void> => {
    const formatFile: Blob = await fetch(GOOGLE_DOCS_BOLT_PATTERN_LINK).then((it: Response) => it.blob())
    // https://docs.google.com/spreadsheets/d/1ejtKQ_skZFv6oAi69wKrk83bRKYV6Op8DxGrPDoX5CY
    const rows: Row[] = await readXlsxFile(formatFile);
    const list: BoltPatternList = new Map<string, string>();
    for (const row of rows) {
        const target: string = row[0] as string
        list.set(row[1] as string, target)
        list.set(row[2] as string, target)
    }
    ReplaceRuleStore.init(list)
}
