import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import React, {JSX} from "react";
import {isEmpty} from "lodash";

interface ErrorProps {
    text?: string | JSX.Element
}

const Error = ({text = ''}: ErrorProps): JSX.Element => {
    return <Dialog open={true}>
        <DialogContent>
            <p>Помилка завантаження</p>
            <p>Спробуйте перезавантажити сторінку</p>
            {isEmpty(text) ? null : <p>{text}</p>}
        </DialogContent>
        <DialogActions>
            <Button onClick={window.location.reload.bind(window.location)}>
                Перезавантажити
            </Button>
        </DialogActions>
    </Dialog>
}

export default Error;
