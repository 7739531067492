import RuleStore from "./verify/RuleStore";
import {CompoundRule, VerifyError} from "./verify/Rule";
import {VoidCallback} from "../common/types";

export type VerifyRuleErrorList = {
    [key: string]: VerifyError;
}

class Item {

    private subscriberID: number = 0;
    private readonly subscribers: Map<number, (item: Item) => void> = new Map();

    constructor(
        public readonly id: number, // Номер строки в файле ексель
        public readonly values: Map<string, string> // Характеристики
    ) {
    }

    public getSku(): string | null {
        return this.values.get('Код артикула') ?? null;
    }

    public subscribeForUpdates(func: (item: Item) => void): VoidCallback {
        this.subscribers.set(++this.subscriberID, func)
        return () => this.subscribers.delete(this.subscriberID)
    }

    public notify(): void {
        this.subscribers.forEach((f): void => f(this))
    }

    public getErrors(keys: string[] | undefined = undefined): VerifyRuleErrorList {
        if (keys === undefined) {
            keys = Array.from(RuleStore.rules.keys())
        }
        const errors: VerifyRuleErrorList = {}
        for (const key of keys) {
            const rule: CompoundRule | undefined = RuleStore.rules.get(key)
            if (rule === undefined) {
                continue;
            }
            const error: VerifyError | null = rule.getError(key, this.values.get(key) ?? '', this)
            if (error === null) {
                continue;
            }
            errors[key] = error
        }
        return errors
    }
}

export default Item;
