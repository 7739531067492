import {JSX} from "react";
import {Grid, Switch} from "@mui/material";

interface HeaderSwitchProps {
    header: string,
    enabled: boolean,
    switchHeaderVisibility: (headerName: string) => void
}

const HeaderSwitch = ({header, enabled, switchHeaderVisibility}: HeaderSwitchProps): JSX.Element => {
    return <Grid textAlign='center' container={true} direction='row'>
        <Grid item={true}>
            <Switch checked={enabled} onChange={(): void => switchHeaderVisibility(header)}/>
            {header}
        </Grid>
    </Grid>
}

export default HeaderSwitch;
