import * as React from "react";
import {ChangeEvent, JSX, useMemo, useRef, useState} from "react";
import Item, {VerifyRuleErrorList} from "../data/Item";
import {
    Button,
    LabelDisplayedRowsArgs,
    Paper,
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import Row from "./Row";
import {flatten, isEmpty, sumBy, uniq} from "lodash";
import RuleStore from "../data/verify/RuleStore";
import HeaderSwitchContainer from "./header_switch/HeaderSwitchContainer";
import {Menu as MenuIcon, Warning} from "@mui/icons-material";
import ScrollToTop from "react-scroll-to-top";
import Menu from "./menu/Menu";
import NamingMenu from "./naming/NamingMenu";
import {NAME_COLUMN_NAMES} from "../data/name/NameFormat";
import DocumentsModal from "./documents/DocumentsModal";
import ReplacementModal from "./replacement/ReplacementModal";

interface TableProps {
    items: Item[]
}

const Table = ({items}: TableProps): JSX.Element => {
    const menuAnchor = useRef<HTMLButtonElement>(null)
    const [menuOpen, setMenuOpen] = useState<boolean>(false)

    const [namingMenuOpen, setNamingMenuOpen] = useState<boolean>(false)
    const [documentsOpen, setDocumentsOpen] = useState<boolean>(false)
    const [replacementOpen, setReplacementOpen] = useState<boolean>(false)

    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(25)

    const [headersStatus, setHeadersStatus] = useState<Map<string, boolean>>(new Map())
    const [headerSwitchOpen, setHeaderSwitchOpen] = useState<boolean>(false)

    const headers: string[] = useMemo((): string[] => {
        const allHeaders: string[][] = items.map((it: Item): string[] => {
            return Array.from(it.values.keys())
        });

        const headers: string[] = uniq<string>(flatten<string>(allHeaders))
        const updateStatus: Map<string, boolean> = new Map<string, boolean>()
        headers.push(...NAME_COLUMN_NAMES)
        headers.forEach((it: string): void => {
            updateStatus.set(it, false)
        })
        setHeadersStatus(updateStatus)
        return uniq(headers)
    }, [items])
    console.log(headers)

    const [productsWithErrors, totalErrors]: [number, number] = useMemo((): [number, number] => {
        const forHeaders: string[] = Array.from(headersStatus.entries()).filter(([_, enabled]): boolean => enabled).map(([name]): string => name)
        const productsWithErrors: VerifyRuleErrorList[] = items
            .map((it: Item): VerifyRuleErrorList => it.getErrors(forHeaders))
            .filter((it: VerifyRuleErrorList): boolean => !isEmpty(it))
        const totalErrors: number = sumBy(productsWithErrors, (it: VerifyRuleErrorList): number => Object.keys(it).length)
        return [productsWithErrors.length, totalErrors]
    }, [items, headersStatus])

    const switchHeaderStatus = (name: string, forceStatus: boolean | undefined = undefined): void => {
        if (!headers.includes(name)) {
            console.error(`Trying to switch unknown header ${name}`)
            return
        }
        const updateStatus: Map<string, boolean> = new Map(headersStatus);
        updateStatus.set(name, forceStatus ?? !updateStatus.get(name))
        setHeadersStatus(updateStatus)
    }

    const selectPreset = (preset: string): void => {
        const updateStatus: Map<string, boolean> = new Map(headers.map((it: string): [string, boolean] => [it, false]));
        RuleStore.presets.get(preset)!!
            .filter((it: string): boolean => headers.includes(it))
            .forEach((it: string): void => {
                updateStatus.set(it, true)
            })
        setHeadersStatus(updateStatus)
    }

    const onPageChange = (_: any, newPage: number): void => {
        setPage(newPage)
    }
    const onRowsPerPageChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    const getPageItems = (): Item[] => {
        return items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    const getVisibleHeaders = (): string[] => {
        return headers.filter((it: string): boolean => headersStatus.get(it)!!)
    }

    if (items.length === 0) {
        return <></>
    }

    return <>
        <Button ref={menuAnchor} size='large' sx={{
            position: 'fixed',
            zIndex: 100,
            top: 4,
            right: 15
        }} endIcon={totalErrors > 0 ? <Warning color='error'/> : null} startIcon={<MenuIcon/>} color='primary'
                onClick={(): void => setMenuOpen(true)}
        />
        <Menu close={(): void => setMenuOpen(false)} isOpen={menuOpen}
              anchor={menuAnchor.current} openNaming={(): void => setNamingMenuOpen(true)}
              totalErrors={totalErrors} productsWithErrors={productsWithErrors}
              openHeaderSwitch={(): void => setHeaderSwitchOpen(true)}
              openDocuments={(): void => setDocumentsOpen(true)}
              openReplace={(): void => setReplacementOpen(true)}
        />
        <ReplacementModal isOpen={replacementOpen} close={(): void => setReplacementOpen(false)} items={items}/>
        <DocumentsModal isOpen={documentsOpen} close={(): void => setDocumentsOpen(false)}/>
        <NamingMenu switchHeader={switchHeaderStatus} headerStatus={headersStatus} isOpen={namingMenuOpen}
                    close={(): void => setNamingMenuOpen(false)} items={items}/>
        <HeaderSwitchContainer switchHeaderVisibility={switchHeaderStatus}
                               enabled={headersStatus}
                               headers={headers}
                               dialogOpen={headerSwitchOpen}
                               close={(): void => setHeaderSwitchOpen(false)}
                               selectPreset={selectPreset}/>
        <TableContainer sx={{height: window.innerHeight - 25, maxHeight: window.innerHeight - 25, marginTop: '25px'}}>
            <MUITable stickyHeader={true}>
                <TableHead>
                    <TableRow>
                        <Tooltip placement='top'
                                 title={<Typography>Номер рядка у файлі ексель</Typography>}>
                            <TableCell>ID</TableCell>
                        </Tooltip>
                        {getVisibleHeaders().map((it: string) => {
                            console.log(it)
                            return <TableCell key={it}>{it}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getPageItems().map((it: Item): JSX.Element => {
                        return <Row key={it.id}
                                    shownHeaders={getVisibleHeaders()}
                                    item={it}
                        />
                    })}
                    <TableRow>
                        <TableCell/>
                    </TableRow>
                    <TableRow>
                        <TableCell/>
                    </TableRow>
                </TableBody>
                <ScrollToTop/>
            </MUITable>
        </TableContainer>
        <TablePagination
            count={items.length}
            sx={{
                boxShadow: 2,
                position: 'fixed',
                bottom: 0,
                right: 0
            }}
            component={Paper}
            page={page}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Товарів на сторінці'
            labelDisplayedRows={(e: LabelDisplayedRowsArgs): string => `${e.from}-${e.to} из ${e.count}`}
            rowsPerPageOptions={[
                {label: '25', value: 25},
                {label: '50', value: 50},
                {label: '100', value: 100},
                {label: '200', value: 200},
                {label: 'Всё', value: Number.MAX_SAFE_INTEGER},
            ]}
            onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange}
        />
    </>
}

export default Table;
