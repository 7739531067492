import {ListItemIcon, ListItemText, Menu as MuiMenu, MenuItem, Typography} from "@mui/material";
import {DoneAll, Error, Link, Subtitles, Tune} from "@mui/icons-material";
import * as React from "react";
import {VoidCallback} from "../../common/types";

interface MenuProps {
    close: VoidCallback;
    isOpen: boolean;
    anchor: HTMLElement | null;
    totalErrors: number;
    productsWithErrors: number;
    openHeaderSwitch: VoidCallback;
    openNaming: VoidCallback;
    openDocuments: VoidCallback;
    openReplace: VoidCallback;
}

const Menu = (props: MenuProps): React.ReactElement => {
    const {
        close,
        isOpen,
        anchor,
        productsWithErrors,
        totalErrors,
        openHeaderSwitch,
        openNaming,
        openDocuments,
        openReplace,
    } = props
    return <MuiMenu onClose={close} open={isOpen} anchorEl={anchor}>
        <MenuItem>
            {totalErrors > 0 ? <>
                <ListItemIcon>
                    <Error/>
                </ListItemIcon>
                <ListItemText>
                    <Typography>
                        Товари з помилками: {productsWithErrors}, всього помилок: {totalErrors}
                    </Typography>
                </ListItemText>
            </> : <>
                <ListItemIcon>
                    <DoneAll color='success'/>
                </ListItemIcon>
                <ListItemText>
                    Помилок немає
                </ListItemText>
            </>}
        </MenuItem>
        <MenuItem onClick={openHeaderSwitch}>
            <ListItemIcon>
                <Tune color='primary'/>
            </ListItemIcon>
            <ListItemText>
                Характеристики
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={openNaming}>
            <ListItemIcon>
                <Subtitles color='primary'/>
            </ListItemIcon>
            <ListItemText>
                Назви
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={openReplace}>
            <ListItemIcon>
                <Subtitles color='primary'/>
            </ListItemIcon>
            <ListItemText>
                Заміна характеристик
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={openDocuments}>
            <ListItemIcon>
                <Link color='primary'/>
            </ListItemIcon>
            <ListItemText>
                Корисні посилання
            </ListItemText>
        </MenuItem>
    </MuiMenu>
}

export default Menu;
