import {DropzoneDialog} from "mui-file-dropzone";
import {Fab} from "@mui/material";
import {useState} from "react";
import {FileUpload} from "@mui/icons-material";
import readXlsxFile from "read-excel-file";
import {Cell, Row as ExcelRow} from "read-excel-file/types"
import Item from "../data/Item";
import toast from "react-hot-toast";

interface FileUploaderProps {
    setItems: (items: Item[]) => void;
    setFilename: (filename: string) => void;
    itemsUploaded: boolean;
}

const FileUploader = ({setItems, setFilename, itemsUploaded}: FileUploaderProps) => {
    const [open, setOpen] = useState<boolean>(true)

    const save = (rows: ExcelRow[]): void => {
        const headers: string[] = rows[0].map((it: Cell | null): string => it?.toString() ?? "")
        rows.splice(0, 1)

        const items: Item[] = rows.map((row: ExcelRow, i: number): Item => {
            const values: Map<string, string> = new Map()
            headers.forEach((header: string, headerIndex: number): void => {
                const prev: string | undefined = values.get(header as string)
                let value: string = (row[headerIndex] ? row[headerIndex] : '').toString()
                if (prev) {
                    value = `${prev};;${value}`
                }
                values.set(header, value)
            })
            return new Item(
                i + 2,
                values
            )
        })
        toast.success(`Завантажено ${items.length} товарів`, {duration: 4000})
        setItems(items)
        setOpen(false)
    }

    return <>
        <DropzoneDialog maxFileSize={104857600}
                        fullWidth={true}
                        cancelButtonText='Відміна'
                        submitButtonText='Завантажити'
                        fileObjects={[]}
                        acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                        onClose={(): void => {
                            if (!itemsUploaded) {
                                return;
                            }
                            setOpen(false)
                        }}
                        onSave={(file: File[]): File[] => {
                            setOpen(false)
                            setFilename(file[0].name)
                            readXlsxFile(file[0]).then((rows: ExcelRow[]): void => save(rows))
                            return file
                        }}
                        clearOnUnmount={true} filesLimit={1}
                        dialogTitle='Оберіть файл'
                        showPreviewsInDropzone={false}
                        dropzoneText='Перетягніть файл сюди'
                        open={open} showPreviews={true} showFileNamesInPreview={true}/>
        <Fab
            sx={{
                position: 'fixed',
                bottom: 0,
                right: 440,
                float: 'right'
            }} color='primary'
            onClick={(): void => setOpen(true)}>
            <FileUpload/>
        </Fab>
    </>
}
export default FileUploader
