import {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    Language,
    LANGUAGE_LIST,
    NAME_COLUMN_NAMES,
    NameFormatStore,
    NameProcessException
} from "../../data/name/NameFormat";
import HeaderSwitch from "../header_switch/HeaderSwitch";
import {isEmpty} from "lodash";
import {HelpOutline, PublishedWithChanges} from "@mui/icons-material";
import Item from "../../data/Item";
import Loader from "../../common/Loader";
import toast from "react-hot-toast";
import {VoidCallback} from "../../common/types";
import ErrorListMenu from "../common/ErrorListMenu";

interface NamingMenuProps {
    isOpen: boolean,
    close: () => void,
    headerStatus: Map<string, boolean>,
    switchHeader: (name: string) => void,
    items: Item[]
}

/**
 * @return errors, grouped by error. {error: sku[]}
 */
const processItems = async (items: Item[], type: string, brand: string, createUrl: boolean): Promise<Map<string, string[]>> => {
    const errors: Map<string, string[]> = new Map<string, string[]>()
    const callbacks: VoidCallback[] = [];

    for (const item of items) {
        try {
            callbacks.push(NameFormatStore.processItem(item, type, brand, createUrl))
        } catch (e) {
            if (e instanceof NameProcessException) {
                e.errors.forEach((err: string): void => {
                    if (errors.has(err)) {
                        errors.get(err)!.push((e as NameProcessException).item.getSku()!)
                    } else {
                        errors.set(err, [(e as NameProcessException).item.getSku()!])
                    }
                })
            } else {
                console.log(e)
            }
        }
    }
    if (errors.size === 0) {
        callbacks.forEach((it: VoidCallback): void => it());
    }
    return errors
}

const NamingMenu = ({isOpen, close, headerStatus, switchHeader, items}: NamingMenuProps): ReactElement => {
    const [type, setType] = useState<string>('')
    const [createUrl, setCreateUrl] = useState<boolean>(true)
    const [brand, setBrand] = useState<string>('')

    const [isLoading, setLoading] = useState<boolean>(false)

    const [errors, setErrors] = useState<Map<string, string[]>>(new Map())

    useEffect((): void => {
        if (!isOpen) {
            setType('')
            // setErrors(new Map()) TODO uncomment
        }
    }, [isOpen])

    const generateNames = (): void => {
        if (isEmpty(type)) {
            toast.error('Оберіть тип')
            return;
        }
        setLoading(true)
        processItems(items, type, brand, createUrl)
            .then((it: Map<string, string[]>): void => {
                if (it.size > 0) {
                    toast.error('Помилки у вашому файлі')
                    setErrors(it)
                } else {
                    toast.success('Готово')
                }
            })
            .catch((e): void => {
                console.log(e)
                toast.error('Помилка, зв\'яжіться з адміністратором')
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    return <>
        {isLoading && <Loader/>}
        <ErrorListMenu errors={errors} close={(): void => setErrors(new Map())}/>
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>
                Генерування назв товарів
            </DialogTitle>
            <DialogContent sx={{paddingTop: '10px !important'}}>
                <Grid container={true} direction='column' spacing={1}>
                    <Grid container={true} item={true} direction='row' spacing={1}>
                        <Grid item={true} width='150px'>
                            <FormControl fullWidth={true}>
                                <InputLabel id='item-type'>Тип товару</InputLabel>
                                <Select variant='standard' value={type}
                                        onChange={(e: SelectChangeEvent): void => setType(e.target.value as string)}
                                        label='Тип товару' labelId='item-type'>
                                    {NameFormatStore.getTypeList().map((it: string): ReactElement => {
                                        return <MenuItem value={it} key={it}>{it}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item={true}>
                            <Tooltip placement='top' title={
                                <Typography>
                                    Не обов'язково. Буде використаний бренд вказаний у товарі
                                </Typography>
                            }>
                                <TextField value={brand}
                                           onChange={(e: ChangeEvent<HTMLInputElement>): void => setBrand(e.target.value)}
                                           label="Бренд" variant="standard"/>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} marginTop='10px' marginLeft='10px'>
                            <Tooltip placement='top' title={
                                <Typography>
                                    Згенерувати посилання на вітрину з назви англійською
                                </Typography>
                            }>
                                <FormControlLabel control={
                                    <Checkbox checked={createUrl}
                                              onChange={(e: ChangeEvent<HTMLInputElement>): void => setCreateUrl(e.target.checked)}
                                    />} label='URL'/>
                            </Tooltip>
                            <Button onClick={generateNames} endIcon={<PublishedWithChanges/>}
                                    color='primary'>
                                Генерувати
                            </Button>

                        </Grid>
                    </Grid>
                    {!isEmpty(type) && <Grid item={true}>
                        <List>
                            {LANGUAGE_LIST.map((lng: Language): ReactElement => {
                                return <ListItem key={lng}>
                                    <ListItemText>
                                <span style={{fontWeight: 'bold'}}>
                                    {lng.toUpperCase()}
                                </span>
                                        <span>: </span>
                                        {NameFormatStore.namePatterns.get(type)!.getLang(lng)}
                                    </ListItemText>
                                </ListItem>
                            })}
                        </List>
                    </Grid>
                    }
                    <Grid container={true} item={true} marginTop='10px' component={Paper}>
                        <Grid display='inline-block' textAlign='center' item={true}>
                            <Typography variant='h6' paddingLeft='8px'>Відображати назви</Typography>
                        </Grid>
                        <Grid display='inline-block' textAlign='center' item={true}>
                            <Tooltip placement='top'
                                     title={<Typography>Будуть згенеровані усі назви незалежно від вибору</Typography>}>
                                <IconButton>
                                    <HelpOutline/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {NAME_COLUMN_NAMES.map((it: string): ReactElement => {
                            return <HeaderSwitch key={it} header={it} enabled={headerStatus.get(it)!}
                                                 switchHeaderVisibility={switchHeader}/>
                        })}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Закрити</Button>
            </DialogActions>
        </Dialog>
    </>
}
export default NamingMenu
