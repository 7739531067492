import {JSX} from "react";
import {FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent} from "@mui/material";
import toast from "react-hot-toast";
import RuleStore from "../../data/verify/RuleStore";

interface PresetSelectorProps {
    selectPreset: (preset: string) => void
}

const PresetSelector = ({selectPreset}: PresetSelectorProps): JSX.Element => {

    const changePreset = (e: SelectChangeEvent): void => {
        toast.success(`Обрано пресет ${e.target.value}`, {duration: 3000})
        selectPreset(e.target.value)
    }

    return <Paper /*sx={sx}*/>
        <FormControl variant='standard' sx={{m: '5px', width: 100}}>
            <InputLabel id='preset'>Preset</InputLabel>
            <Select labelId='preset' label='Preset' value='0'
                    onChange={changePreset}>
                <MenuItem value={0}></MenuItem>
                {Array.from(RuleStore.presets.keys()).map((it: string): JSX.Element => {
                    return <MenuItem value={it} key={it}>
                        {it}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </Paper>
}

export default PresetSelector;
