import {ChangeEvent, JSX, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import toast from "react-hot-toast";

interface PasswordPromptProps {
    isOpen: boolean,
    close: () => void,
    runUpdate: (password: string) => void
}

const PasswordPrompt = ({isOpen, close, runUpdate}: PasswordPromptProps): JSX.Element => {

    const [password, setPassword] = useState<string>('')

    const save = (): void => {
        if (password.length !== 6) {
            toast.error('Перевірте пароль')
            return
        }
        runUpdate(password)
    }

    return <>
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Збереження</DialogTitle>
            <DialogContent>
                <Typography>
                    Введіть пароль
                </Typography>
                <TextField
                    type='password'
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => setPassword(e.target.value)}
                    variant='standard'/>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    Отмена
                </Button>
                <Button onClick={save}>
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default PasswordPrompt;
