import Item from "../Item";
import {Pair, removeExtraSpaces, stringToList, substringAfter, substringBefore} from "../../util/util";
import {isEmpty} from "lodash";

export interface Param {
    /**
     * @return Pair<EN, EU>, null если значение не найдено
     */
    getValue(name: string, item: Item): LangPair | null
}

class ParamException extends Error {
    constructor(public readonly error: string, public readonly item: Item) {
        super(error);
    }
}

class LangPair extends Pair<string, string> {
    public static same(val: string): LangPair {
        return new LangPair(val, val);
    }

    public en(): string {
        return this.first;
    }

    public eu(): string {
        return this.second;
    }

    public get(isEU: boolean): string {
        return isEU ? this.eu() : this.en();
    }
}

class BrandParam implements Param {
    public getValue(_: string, item: Item): LangPair | null {
        const value: string | undefined = item.values.get('Бренд')
        return value === undefined ? null : LangPair.same(value)
    }
}

class DefaultParam implements Param {
    public getValue(name: string, item: Item): LangPair | null {
        const value: string | undefined = item.values.get(name)
        return value === undefined ? null : LangPair.same(value)
    }
}

class ETParam implements Param {
    public getValue(_: string, item: Item): LangPair | null {
        let value: string | undefined = item.values.get('ET');
        if (isEmpty(value)) {
            value = undefined
        }
        if (value === undefined) {
            throw new ParamException('ET не указан', item);
        }
        if (Number(value) > 0) {
            value = `+${value}`
        }
        return new LangPair(value, `ET${value}`);
    }
}

class WheelSize implements Param {
    public getValue(_: string, item: Item): LangPair | null {
        const diameter: string | undefined = item.values.get('Посадочный диаметр')
        const profileWidth: string | undefined = item.values.get('Ширина профиля (дюйм)')
        if (diameter === undefined) {
            return LangPair.same('(Посадочный диаметр не найден)')
        }
        if (profileWidth === undefined) {
            return LangPair.same('(Ширина профиля (дюйм) не найден)')
        }
        return LangPair.same(`${diameter}x${profileWidth}`)
    }
}

class Beadlock implements Param {
    public getValue(_: string, item: Item): LangPair | null {
        if (item.values.get('Series')?.includes('Beadlock')) {
            return null;
        }
        return item.values.get('Бедлок') === 'Да' ? LangPair.same('Beadlock') : null
    }
}

class AutoParam implements Param {
    getValue(_: string, item: Item): LangPair | null {
        const raw: string | undefined = item.values.get('auto')?.trim();
        if (raw === undefined || raw === 'all' || raw === '') {
            return null;
        }
        const auto: string[] = stringToList(raw);

        interface Car {
            brand: string;
            model: string;
        }

        const cars: Car[] = auto.map((car: string) =>
            (substringAfter(car, '||'))
                .replace("Ram||", "Dodge Ram||")
                .replace("Dodge||Ram ", "Dodge Ram||")
        ).filter((value: string, index: number, self: string[]): boolean => self.indexOf(value) === index)
            .map((carStr: string): { brand: string, model: string } => {
                const [brand, model] = carStr.split("||");
                return {brand, model};
            });
        const years: number[] = auto.map((it: string): number => parseInt(substringBefore(it, '||')));
        const minYear: string = Math.floor(Math.min.apply(Math, years)).toString().slice(-2);
        const maxYear: string = Math.floor(Math.max.apply(Math, years)).toString().slice(-2);

        const groupedCars: Record<string, Car[]> = cars.reduce((acc: Record<string, Car[]>, car: Car): Record<string, Car[]> => {
            const key: string = ["GMC", "Chevrolet"].includes(car.brand) ? "Chevy" : car.brand;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(car);
            return acc;
        }, {} as Record<string, Car[]>);

        const autoString: string = Object.values(groupedCars).map((group: Car[]): string => {
            let brand: string = group[0].brand;
            if (group.some((car: Car): boolean => car.brand === "GMC") && group.some((car: Car): boolean => car.brand === "Chevrolet")) {
                brand = "GMC/Chevrolet";
            }
            let model: string = group.map(car => car.model).join("/");
            if (brand.includes('/')) {
                const silveradoModels: Car[] = group.filter(car => car.model.includes("Sierra") || car.model.includes("Silverado"));
                if (silveradoModels.length > 0) {
                    model = "Sierra/Silverado";
                    const silveradoYears = Array.from({length: 6}, (_, i) => 1500 + i * 1000)
                        .filter(year => silveradoModels.some(car => car.model.includes(year.toString())));
                    model += ' ' + silveradoYears.join("/");
                    model += ' ' + group.filter(car => !silveradoModels.includes(car)).map(car => car.model).join("/");
                    model = model.trim();
                }
            }
            return `${brand} ${model}`
                .replace("GMC/Chevrolet Sierra/Silverado", "Chevrolet Silverado/GMC Sierra ")
                .replace("GMC/Chevrolet Tahoe/Yukon", "Chevrolet Tahoe/GMC Yukon ")
                .replace("GMC/Chevrolet Colorado/Canyon", "Chevrolet Colorado/GMC Canyon ")
                .replace("GMC/Chevrolet Canyon/Colorado", "Chevrolet Colorado/GMC Canyon ")
                .replace(/Silverado (\d+)\/Silverado (\d+)/g, (_, p1, p2) => `Silverado ${p1}/${p2}`)
                .replace(/Sierra (\d+)\/Sierra (\d+)/g, (_, p1, p2) => `Sierra ${p1}/${p2}`);
        }).join("/")
            .replace("Ram 1500/1500 Classic", "Ram 1500")
            .replace("Ram 1500 Classic/1500", "Ram 1500")
            .replace("Silverado 1500/Silverado 1500", "Silverado 1500")
            .replace("Sierra 1500/Sierra 1500", "Sierra 1500")
            .replace("Wrangler JK/Wrangler JL", "Wrangler JK/JL")
            .replace("Wrangler JL/Wrangler JK", "Wrangler JK/JL");
        const sub: string = stringToList(item.values.get('Sub model') ?? '').join('/');
        const string: string = `${autoString} ${sub} ${minYear}-${maxYear}`

        return LangPair.same(removeExtraSpaces(string));
    }
}

abstract class ParamStore {
    public static readonly specialParams: Map<string, Param> = new Map([
        ['Brand', new BrandParam()],
        ['ET', new ETParam()],
        ['Размер диска', new WheelSize()],
        ['Beadlock', new Beadlock()],
        ['auto', new AutoParam()],
    ])

    public static getSpecialParam(name: string): Param {
        return this.specialParams.get(name) ?? new DefaultParam();
    }
}

export {
    BrandParam,
    ETParam,
    LangPair,
    ParamStore,
    AutoParam,
    ParamException,
}
